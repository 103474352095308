import styled from 'styled-components';

export const PresetCommentContainer = styled.ul`
    display: flex;
   flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
}
`;
export const PresetCommentStyle = styled.li`
  border: 1px solid #ccc;
  margin: 5px 10px;
  border-radius: 20px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  padding: 8px 15px;
  font-size: 18px;
  cursor: pointer;
  align-self: center;
  text-align: center;
  &:hover {
    background-color: #f0f0f0;
  }
`;
