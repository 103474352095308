import React from 'react';
import { Container, DateContainer, Email, Row, Text } from './styles';
import { Comment as CommentGeneratedType } from '@graphql/generated';
import { format } from 'date-fns';

export type CommentType = Pick<CommentGeneratedType, 'id' | 'createdAt' | 'changed_by' | 'text'>;

interface Props {
  comment: CommentType;
}

const Comment: React.FC<Props> = (props) => {
  const { comment } = props;
  return (
    <Container>
      <Row>
        <Email>{comment.changed_by}</Email>
        <DateContainer>{format(comment.createdAt, 'dd/MM/yyyy HH:mm')}</DateContainer>
      </Row>
      <Text>{comment.text}</Text>
    </Container>
  );
};

export default Comment;
