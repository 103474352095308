import { Scan, useSearchAllScansInfoLazyQuery } from '@graphql/generated';
import { useState } from 'react';
import { reportAllPagesResults } from '@helpers/reportAllPagesResults';

interface Result {
  scans: Scan[];
  getScans: () => Promise<void>;
  loading: boolean;
}

export const useAllScansQuery = (
  siteScanId: string,
  selectedConfidence: string[],
  selectedStatus: string[]
): Result => {
  const [scans, setSScans] = useState<Scan[]>([]);
  const [getData, data] = useSearchAllScansInfoLazyQuery();
  const [loading, setLoading] = useState(false);

  const getDataByFilters = async (): Promise<void> => {
    setSScans([]);
    if (!siteScanId || selectedConfidence.length === 0 || selectedStatus.length === 0) return;
    setLoading(true);
    await reportAllPagesResults(
      getData,
      (res) => {
        const filteredItems =
          res?.data?.searchScans?.items?.filter((item) => item?.analyzed_entities?.items?.length) ||
          [];

        const filterScans = filteredItems
          .map((item) => ({
            ...item,
            analyzed_entities: {
              ...item?.analyzed_entities,
              items: item?.analyzed_entities?.items.filter(
                (entity) =>
                  selectedConfidence.includes(entity?.confidence?.toLocaleUpperCase() ?? '') &&
                  selectedStatus.includes(entity?.status?.toLocaleUpperCase() ?? '')
              )
            }
          }))
          .filter((item) => item?.analyzed_entities?.items?.length);
        setSScans((scans) => [...scans, ...((filterScans || []) as Scan[])]);
      },
      (res) => res.data?.searchScans?.nextToken,
      { siteScanId }
    ).finally(() => {
      setLoading(false);
    });
  };

  return {
    scans,
    getScans: getDataByFilters,
    loading
  };
};
