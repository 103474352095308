import React, { useState, useContext, useEffect } from 'react';
import { Container, Icon, Row, Text } from './styles';
import Button from '@components/Button';
import CommentsIcon from '@assets/comments.svg';
import CustomPopUp from '@components/PopUp/CustomPopUp';
import CommentsPopUp from './CommentsPopUp';
import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import {
  Confidence,
  useUpdateAnalyzedEntityByIdMutation,
  useSearchCommentsByFilterLazyQuery,
  AnalyzedEntity
} from '@graphql/generated';

import { useUrlParams } from '@hooks/useUrlParams';
import SelectInput from '@components/SelectInput';
import FlagIcon from '@assets/flag.svg';
import { CommentType } from './CommentsPopUp/Comments/Comment';
import Select from 'react-select';

const confidentOrder = [
  Confidence.None,
  Confidence.Lowest,
  Confidence.Low,
  Confidence.Medium,
  Confidence.High,
  Confidence.Highest
];

const Feedback: React.FC = () => {
  const { analyzedEntity, updateAnalyzedEntity, analyzedEntityList, setAnalyzedEntityList } =
    useContext(AnalyzedEntityContext);
  const [getComments, { data }] = useSearchCommentsByFilterLazyQuery();
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [comments, setComments] = useState<CommentType[]>([]);
  const [updateAnalyzedEntityById, { loading }] = useUpdateAnalyzedEntityByIdMutation();
  const [addedCommentsLength, setAddedCommentsLength] = useState<number>(0);
  const closePopUp = (): void => setIsPopUpOpen(false);
  const openPopUp = (): void => setIsPopUpOpen(true);
  const { getUrlParam } = useUrlParams();
  const currentId = getUrlParam('currentId') ?? '';
  const getCommentsWithFilter = (): void => {
    void getComments({
      variables: {
        filter: {
          analyzedEntityCommentsId: { eq: currentId }
        }
      },
      fetchPolicy: 'no-cache'
    });
  };
  useEffect(() => {
    getCommentsWithFilter();
    setAddedCommentsLength(0);
  }, [currentId]);
  useEffect(() => {
    setComments(filteredAndSortedComments);
  }, [data]);
  const filteredAndSortedComments = (
    (data?.searchComments?.items.filter((item) => !!item) as CommentType[]) || []
  ).sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
  const updateConfidence = (confidence: Confidence | undefined): void => {
    if (!confidence) return;
    const currentId = analyzedEntity?.id;
    if (!currentId) {
      return;
    }
    updateAnalyzedEntityById({
      variables: {
        updateAnalyzedEntityInput: {
          id: currentId,
          analysis_confidence: confidence
        }
      }
    })
      .then((r) => {
        const updatedAnalyzedEntity = { ...analyzedEntity, analysis_confidence: confidence };
        const index = analyzedEntityList.findIndex((item) => item.id === currentId);
        const updatedList = [...analyzedEntityList];
        updatedList[index] = updatedAnalyzedEntity;
        setAnalyzedEntityList(updatedList);
        updateAnalyzedEntity(updatedAnalyzedEntity);
      })
      .catch((e) => console.error('Error while updating analyzed entity confidence', e));
  };

  const updateExoAnalyzerFlag = (): void => {
    const currentId = analyzedEntity?.id;
    if (!currentId) {
      return;
    }
    updateAnalyzedEntityById({
      variables: {
        updateAnalyzedEntityInput: {
          id: currentId,
          exoanalyzer_flagged: true
        }
      }
    }).catch((e) => console.error('Error while updating analyzed entity exoanalyzer_flagged', e));
  };

  const confidenceOptions = confidentOrder
    .filter((item) => item !== Confidence.NoPipe)
    .map((item) => {
      return {
        key: item,
        value: item
      };
    });

  const currentConfidence = analyzedEntity?.confidence;
  const currentAnalysisConfidence = analyzedEntity?.analysis_confidence || currentConfidence;

  return (
    <Container>
      <Row>
        <Text>Confidence: </Text>
        {!currentConfidence || loading ? (
          <Text>Loading</Text>
        ) : (
          <>
            <Select
              onChange={(newVal) => updateConfidence(newVal?.key)}
              value={confidenceOptions.find((c) => c.key === currentAnalysisConfidence)}
              getOptionLabel={(opt) => opt.value}
              getOptionValue={(opt) => opt.key}
              options={confidenceOptions}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              menuPlacement="top"
              isLoading={loading}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                control: (provided) => ({
                  ...provided,
                  maxWidth: 150,
                  width: 150
                })
              }}
            />
            <div> [{currentConfidence}]</div>
          </>
        )}
      </Row>

      <Button onClick={updateExoAnalyzerFlag} disabled={loading}>
        <Row>
          <Icon src={FlagIcon} />
          ExoAnalyzer Flag
        </Row>
      </Button>

      <Button onClick={openPopUp}>
        <Row>
          <Icon src={CommentsIcon} />
          Comment
          {analyzedEntity?.comments?.items && analyzedEntity?.comments?.items.length > 0
            ? ' (1) '
            : ''}
        </Row>
      </Button>
      <CustomPopUp horizontalPosition={'end'} verticalPosition={'end'} isOpen={isPopUpOpen}>
        <CommentsPopUp
          comments={comments}
          setComments={setComments}
          onClosePopUp={closePopUp}
          setAddedCommentsLength={setAddedCommentsLength}
          addedCommentsLength={addedCommentsLength}
        />
      </CustomPopUp>
    </Container>
  );
};

export default Feedback;
