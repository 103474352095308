import { Scan } from '@graphql/generated';
import { useEffect, useState } from 'react';
import { Option, SELECT_ALL } from './MultiSelect';

export const useDatesOptions = (scans: Scan[]) => {
  const [value, setValue] = useState<Option[]>([]);

  useEffect(() => {
    const dates = Object.values(
      scans.reduce(
        (acc, item) => ({
          ...acc,
          [item.scan_date]: item.scan_date
        }),
        {
          SELECT_ALL
        }
      )
    ).map((v) => ({
      label: v,
      value: v
    }));

    setValue(dates.sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime()));

    return () => setValue([]);
  }, [scans]);

  return value;
};
