import { Status } from '@graphql/generated';
import { CrossingI } from '@pages/Main/DetailsView';
import { create } from 'zustand';

type CrossingViewStore = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  crossingData: Record<string, CrossingI>;
  setCrossingData: (id: string, crossingData: CrossingI) => void;
  crossingsStatus: Record<string, (Status | null)[]>;
  setCrossingStatus: (id: string, status: (Status | null)[]) => void;
};

export const useCrossingViewStore = create<CrossingViewStore>((set) => ({
  crossingsStatus: {},
  setCrossingStatus: (id, status) =>
    set((state) => ({ crossingsStatus: { ...state.crossingsStatus, [id]: status } })),
  isLoading: true,
  setIsLoading: (isLoading) => set((state) => ({ isLoading })),
  crossingData: {},
  setCrossingData: (id, crossingData) =>
    set((state) => ({ crossingData: { ...state.crossingData, [id]: crossingData } }))
}));
