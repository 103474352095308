import { useEffect, useState } from 'react';
import { SearchContainer } from './styles';
import { Scan, Site } from '@graphql/generated';
import { useEffectAsync } from '@hooks/useEffectAsync';
import { useAllSitesQuery } from '@hooks/prefetch/usePrefetchAllSites';
import { useAllScansQuery } from '@hooks/prefetch/usePrefetchAllScans';
import { LoadingSpinner } from '@components/LoaderSpinner';
import { MultiSelect, SELECT_ALL } from './MultiSelect';
import { ConfidenceOptions, StatusOptions, CountSearch } from './constans';

import Select from 'react-select';
import { createLinks } from './createLink';
import { useScanNamesOptions } from './useScanNamesOptions';
import { useDatesOptions } from './useDatesOptions';

const Search = () => {
  const [selectedSite, setSelectedSite] = useState<Site | null>();
  const [selectedConfidence, setSelectedConfidence] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>(['PENDING']);
  const [selectedScans, setSelectedScans] = useState<Scan[]>([]);
  const [selectedDate, setSelectedDates] = useState<any[]>([]);
  const [links, setLinks] = useState<string[]>([]);
  const [selectedMaxValue, setSelectedMaxValue] = useState(50);

  const { sites, getSites, loading } = useAllSitesQuery();
  const {
    scans,
    getScans,
    loading: loadScan
  } = useAllScansQuery(selectedSite?.id || '', selectedConfidence, selectedStatus);

  const scanNameOptions = useScanNamesOptions(scans);
  const scanDateOptions = useDatesOptions(selectedScans);

  const onScanNameChange = (value: string[]) => {
    setSelectedScans(scans.filter((s) => value.includes(s.scan_name)));
  };

  const onScanDateChange = (value: string[]) => {
    setSelectedDates(value);
  };

  useEffectAsync(async () => {
    setLinks([]);
    await getScans();
  }, [selectedConfidence, selectedStatus, selectedSite]);

  useEffect(() => {
    if (!selectedScans.length) return setLinks([]);
    const newLinks: string[] = createLinks({
      selectedScans,
      selectedMaxValue,
      selectedDate
    });
    setLinks([...newLinks] || []);
  }, [selectedScans, selectedMaxValue, selectedDate]);

  useEffectAsync(async () => {
    await getSites();
  }, []);

  return (
    <SearchContainer>
      <h1>PipesQA - Search Page</h1>
      <>
        <Select
          isLoading={loading}
          id="site-select"
          onChange={(newVal) => setSelectedSite(newVal as Site)}
          value={sites.find((opt) => opt.id === selectedSite?.id)}
          isSearchable
          isClearable
          placeholder="Select Site...."
          options={sites}
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => opt.id}
        />
        {!!sites.length && (
          <>
            <MultiSelect
              placeholder="Select Confidence..."
              options={ConfidenceOptions}
              onChange={(newVal) => setSelectedConfidence(newVal)}
            />
            <MultiSelect
              placeholder="Select Status..."
              options={StatusOptions}
              defaultValue={StatusOptions.find((s) => s.value === 'PENDING')}
              onChange={(newVal) => setSelectedStatus(newVal)}
            />
          </>
        )}
        {loadScan ? (
          <LoadingSpinner />
        ) : scans.length > 0 ? (
          <>
            <hr
              style={{
                width: '100%',
                borderColor: 'rgba(0,0,0,0.2)'
              }}
            />
            <MultiSelect
              placeholder="Select Scans..."
              isLoading={loadScan}
              options={scanNameOptions}
              onChange={onScanNameChange}
            />
            <MultiSelect
              placeholder="Select Dates..."
              options={scanDateOptions}
              onChange={onScanDateChange}
              isLoading={loadScan}
            />
            <div>
              <label htmlFor="max-value-select">Entities per link</label>
              <Select
                id="max-value-select"
                value={CountSearch.find((opt) => opt.value === selectedMaxValue)}
                onChange={(newVal) => setSelectedMaxValue(newVal?.value || 50)}
                options={CountSearch}
                isSearchable
                isClearable
                placeholder="Select max value..."
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: 200
                  })
                }}
              />
              <h2>Links to PipesQA:</h2>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '16px' }}>
                {links.map((link, index) => (
                  <div key={link} style={{ paddingBottom: '16px' }}>
                    <a key={link} href={link} target="_blank" rel="noreferrer">
                      PipesQA Link{index + 1}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500, fontSize: 18 }}>
            No Data
          </div>
        )}
      </>
    </SearchContainer>
  );
};

export default Search;
