import { Confidence, Scan } from '@graphql/generated';

export const sliceLinks = (ids: string[], selectedMaxValue: number): string[] => {
  if (ids.length === 0) return [];
  const links = [];
  const uniqueIds = Array.from(new Set(ids));
  for (let i = 0; i < uniqueIds.length; i += selectedMaxValue) {
    const chunk = uniqueIds.slice(i, i + selectedMaxValue);
    const currentId = chunk[0];
    const analyzedEntitiesIds = chunk.join('%2C');
    const newLink = `${process.env.REACT_APP_SSO_REDIRECT_URI}main?analyzedEntitiesIds=${analyzedEntitiesIds}&currentId=${currentId}`;
    links.push(newLink);
  }
  return links;
};

export const createLinks = ({
  selectedScans,
  selectedMaxValue,
  selectedDate
}: {
  selectedScans: Scan[];
  selectedMaxValue: number;
  selectedDate: string[];
}): string[] => {
  if (!selectedScans?.length || !selectedDate.length) return [];
  const entities = selectedScans
    .filter((s) => {
      return selectedDate.includes(s.scan_date);
    })
    .sort((a, b) => new Date(a.scan_date).getTime() - new Date(b.scan_date).getTime())
    .map((scan) => scan.analyzed_entities?.items)
    .flat()
    .sort((a, b) => {
      if (a?.confidence === b?.confidence) return 0;
      const confidenceOrder = [
        Confidence.Highest,
        Confidence.High,
        Confidence.Medium,
        Confidence.Low,
        Confidence.Lowest,
        Confidence.None
      ];
      const confidenceA = confidenceOrder.indexOf(a?.confidence || Confidence.None);
      const confidenceB = confidenceOrder.indexOf(b?.confidence || Confidence.None);
      return confidenceA - confidenceB;
    })
    .map((item) => {
      return item?.id;
    })
    .flat()
    .filter((item): item is string => item !== undefined && item !== null);
  return sliceLinks(entities, selectedMaxValue);
};
