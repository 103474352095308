import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import React, { useContext } from 'react';
import { StatusesDiv } from './styles';

const Statuses = () => {
  const { statuses, analyzedEntityList } = useContext(AnalyzedEntityContext);
  return (
    <StatusesDiv>
      {statuses.approved + statuses.rejected}/{analyzedEntityList.length}
    </StatusesDiv>
  );
};

export default Statuses;
