import React, { useContext, useState } from 'react';
import { Container } from './styles';
import Header from './Header';
import Input from '@pages/Main/DetailsView/Feedback/CommentsPopUp/Input';
import { useCreateCommentMutation } from '@graphql/generated';
import UserContext from '@contexts/UserContext';
import { useUrlParams } from '@hooks/useUrlParams';
import { CommentType } from './Comments/Comment';
import Comments from './Comments';
import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import PresetComments, { PresetCommentInterface } from './PresetComments';
import { presetComments } from '@constants/presetComments';
import { set } from 'date-fns';

interface Props {
  onClosePopUp?: () => void;
  setAddedCommentsLength: React.Dispatch<React.SetStateAction<number>>;
  addedCommentsLength: number;
  comments: CommentType[];
  setComments: React.Dispatch<React.SetStateAction<CommentType[]>>;
}

export const CommentsPopUp: React.FC<Props> = (props) => {
  const { haveComments, setHaveComments } = useContext(AnalyzedEntityContext);
  const { onClosePopUp, setAddedCommentsLength, addedCommentsLength, comments, setComments } =
    props;
  const [text, setText] = useState('');
  const [createComment, { loading }] = useCreateCommentMutation();
  const { user } = useContext(UserContext);
  const { getUrlParam } = useUrlParams();
  const [currentPresetComment, setCurrentPresetComment] = useState<PresetCommentInterface>(
    presetComments as unknown as PresetCommentInterface
  );
  const currentId = getUrlParam('currentId') ?? '';

  const onSend = (): void => {
    if (!currentId) {
      return;
    }

    createComment({
      variables: {
        createCommentsInput: {
          text,
          changed_by: user?.account?.username || '',
          analyzedEntityCommentsId: currentId
        }
      }
    })
      .then(() => {
        setText('');
        const comment = {
          text,
          changed_by: user?.account?.username,
          analyzedEntityCommentsId: currentId,
          createdAt: new Date().toISOString()
        };
        if (comments.length === 0) {
          setHaveComments(haveComments + 1);
        }
        const tempComments = [...comments, comment];
        setComments(tempComments as CommentType[]);
        setAddedCommentsLength(addedCommentsLength + 1);
        if (onClosePopUp) {
          onClosePopUp();
        }
      })
      .catch((e) => console.error('Error while creating comment', e));
    setCurrentPresetComment(presetComments as unknown as PresetCommentInterface);
  };

  const onClickPresetComment = (presetComment: PresetCommentInterface): void => {
    if (presetComment.next) {
      setText(presetComment.text.slice(0, -1));
      setCurrentPresetComment(presetComment.next as unknown as PresetCommentInterface);
    } else {
      setText(text + ' ' + presetComment.text);
      setCurrentPresetComment(presetComments as unknown as PresetCommentInterface);
    }
  };

  return (
    <Container>
      <Header onClosePopUp={onClosePopUp} />
      <Comments comments={comments} />
      <PresetComments
        onClickPresetComment={onClickPresetComment}
        currentPresetComment={currentPresetComment as unknown as PresetCommentInterface[]}
      />
      <Input onSend={onSend} text={text} setText={setText} sendDisabled={loading || !text.trim()} />
    </Container>
  );
};

export default CommentsPopUp;
