import { Scan } from '@graphql/generated';
import { useEffect, useState } from 'react';
import { SELECT_ALL } from './MultiSelect';
import { Option } from '@components/MultiSelectInput';

function extractSortKey(s: string): [string, number, number] {
  const regex = /(\D+)(\d*)_(\d*)/;
  const match = s.match(regex);

  if (match) {
    const [, prefix, num1, num2] = match;
    return [prefix, parseInt(num1, 10) || 0, parseInt(num2, 10) || 0];
  }

  return [s, 0, 0];
}

function sortStrings(arr: string[]): string[] {
  if (!arr?.length) return [];
  return arr.sort((a, b) => {
    const [prefixA, num1A, num2A] = extractSortKey(a);
    const [prefixB, num1B, num2B] = extractSortKey(b);

    // Compare prefix first
    if (prefixA !== prefixB) {
      return prefixA.localeCompare(prefixB);
    }
    if (num1A !== num1B) {
      return num1A - num1B;
    }
    return num2A - num2B;
  });
}

export const useScanNamesOptions = (scans: Scan[]) => {
  const [value, setValue] = useState<Option[]>([]);
  useEffect(() => {
    const arr = Array.from(new Set(sortStrings(scans?.map((s) => s.scan_name))))?.map((s) => ({
      label: s,
      value: s
    }));
    arr.unshift({
      label: SELECT_ALL,
      value: SELECT_ALL
    });
    setValue(arr);

    return () => setValue([]);
  }, [scans]);

  return value;
};
