import React, { useContext } from 'react';
import { MapButtonsContainer, Text, Row } from './styles';
import Switch from '@components/Switch';
import { Option } from '@components/SelectInput';
import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import Select from 'react-select';

interface Props {
  isOrthophotoLayerOn: boolean;
  onChangeOrthophotoLayerOn: (value: boolean) => void;
  isScanPathLayerOn: boolean;
  onChangeScanPathLayerOn: (value: boolean) => void;
  isUtilitiesLayerOn: boolean;
  onChangeUtilitiesLayerOn: (value: boolean) => void;
  interpolationOptions: Option[];
  selectedInterpolationId: Option['key'];
  setSelectedInterpolationId: (key: Option['key']) => void;
  isCrossingIndex: boolean;
  isYPeaks: boolean;
  onChangeYPeaks: (value: boolean) => void;
  onChangeCrossingIndex: (value: boolean) => void;
  isFixedScanPath: boolean;
  onChangeFixedScanPath: (value: boolean) => void;
}

const MapLayerToggles: React.FC<Props> = (props) => {
  const {
    isOrthophotoLayerOn,
    onChangeOrthophotoLayerOn,
    isScanPathLayerOn,
    onChangeScanPathLayerOn,
    isUtilitiesLayerOn,
    onChangeUtilitiesLayerOn,
    interpolationOptions,
    selectedInterpolationId,
    setSelectedInterpolationId,
    isCrossingIndex,
    isYPeaks,
    onChangeYPeaks,
    onChangeCrossingIndex,
    isFixedScanPath,
    onChangeFixedScanPath
  } = props;
  const { depthList } = useContext(AnalyzedEntityContext);

  const depthOptions: Option[] = Object.keys(depthList).map((val) => {
    return { key: val, value: val };
  });

  depthOptions.push({ key: 'NONE', value: 'NONE' });

  return (
    <MapButtonsContainer>
      <Row>
        <Select
          id="ss"
          menuPlacement="top"
          name="Interoplation"
          onChange={(newVal) => setSelectedInterpolationId(newVal!.key)}
          options={interpolationOptions}
          getOptionLabel={(opt) => opt.value}
          getOptionValue={(opt) => opt.key}
          value={interpolationOptions.find((o) => o.key === selectedInterpolationId)}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (provided) => ({
              ...provided,
              maxWidth: 250,
              width: 250
            })
          }}
        />
      </Row>
      <Row>
        <Switch onChange={onChangeOrthophotoLayerOn} checked={isOrthophotoLayerOn} />
        <Text>Orthophoto</Text>
      </Row>
      <Row>
        <Switch onChange={onChangeYPeaks} checked={isYPeaks} />
        <Text>Y Peaks</Text>
      </Row>
      <Row>
        <Switch onChange={onChangeScanPathLayerOn} checked={isScanPathLayerOn} />
        <Text>Scan Path</Text>
      </Row>
      <Row>
        <Switch
          onChange={onChangeFixedScanPath}
          checked={isFixedScanPath && isScanPathLayerOn}
          disabled={!isScanPathLayerOn}
        />
        <Text className={!isScanPathLayerOn ? 'disabled' : ''}>Fixed Scan Path</Text>
      </Row>
      <Row>
        <Switch onChange={onChangeCrossingIndex} checked={isCrossingIndex} />
        <Text>Crossing Index</Text>
      </Row>
      <Row>
        <Switch onChange={onChangeUtilitiesLayerOn} checked={isUtilitiesLayerOn} />
        <Text>Other Utilities</Text>
      </Row>
    </MapButtonsContainer>
  );
};

export default MapLayerToggles;
