import React from 'react';
import Portal from '@components/Portal';
import { Container, Overlay } from './styles';
import { Position } from './types';

interface Props {
  children: JSX.Element;
  verticalPosition?: Position;
  horizontalPosition?: Position;
  isOpen?: boolean;
  zIndex?: number;
  overlay?: boolean;
}

const CustomPopUp: React.FC<Props> = (props) => {
  const {
    children,
    horizontalPosition = 'center',
    verticalPosition = 'center',
    isOpen,
    zIndex = 10000,
    overlay
  } = props;

  if (!isOpen) {
    return <></>;
  }

  return (
    <Portal>
      <Container
        verticalPosition={verticalPosition}
        horizontalPosition={horizontalPosition}
        zIndex={zIndex}>
        {overlay && <Overlay />}
        {children}
      </Container>
    </Portal>
  );
};

export default CustomPopUp;
