import React, { createContext, useEffect, useState } from 'react';
import { Status, useGetAnalyzedEntityByIdLazyQuery } from '@graphql/generated';
import { InterpolationData } from '@components/Map';
import { CrossingI } from '@pages/Main/DetailsView';
import { LatLngTuple } from 'leaflet';

export type AnalyzedEntity = NonNullable<
  NonNullable<ReturnType<typeof useGetAnalyzedEntityByIdLazyQuery>[1]['data']>['getAnalyzedEntity']
>;

export type NonNullable<T> = Exclude<T, null | undefined>;

export type InterpolationObject = NonNullable<
  NonNullable<NonNullable<AnalyzedEntity['scan']>['interpolations']>['items'][number]
>;

interface InterpolationType {
  interpolationObject: InterpolationObject;
  interpolationData: InterpolationData;
}

export type CrossingObject = NonNullable<
  NonNullable<NonNullable<AnalyzedEntity>['crossings']>['items'][number]
>;

interface CrossingType {
  crossingObject: CrossingObject;
  crossingData: CrossingI;
}

export interface DepthItemInterface {
  crossing_index: number;
  location: {
    center: LatLngTuple;
    points?: LatLngTuple[];
  };
  depth: number;
}

export type DepthListType = Record<string, DepthItemInterface[]>;

export interface DepthSelectedItemInterface {
  depth: DepthItemInterface[];
  key: string;
}

const AnalyzedEntityContext = createContext<{
  analyzedEntity: AnalyzedEntity | undefined;
  setAnalyzedEntity: (analyzedEntity: AnalyzedEntity) => void;
  analyzedEntityList: AnalyzedEntity[];
  setAnalyzedEntityList: (analyzedEntities: AnalyzedEntity[]) => void;

  updateAnalyzedEntity: (newAnalyzedEntity: AnalyzedEntity) => void;

  selectedInterpolation: InterpolationType | undefined;
  setSelectedInterpolation: (analyzedEntities: InterpolationType | undefined) => void;
  interpolationsList: InterpolationType[];
  setInterpolationsList: (analyzedEntities: InterpolationType[]) => void;

  selectedCrossing: CrossingType | undefined;
  setSelectedCrossing: (analyzedEntities: CrossingType | undefined) => void;
  crossingsList: CrossingType[];
  setCrossingsList: (analyzedEntities: CrossingType[]) => void;

  selectedDepth: DepthSelectedItemInterface | undefined;
  setSelectedDepth: (depth: DepthSelectedItemInterface | undefined) => void;
  depthList: DepthListType;
  setDepthList: (depthList: DepthListType) => void;
  statuses: StatusesInterface;
  setStatuses: (statuses: StatusesInterface) => void;
  flagged: number;
  setFlagged: (flagged: number) => void;
  haveComments: number;
  setHaveComments: (haveComments: number) => void;
}>({
  analyzedEntity: undefined,
  setAnalyzedEntity: (analyzedEntity: AnalyzedEntity) => {},
  analyzedEntityList: [],
  setAnalyzedEntityList: (analyzedEntities: AnalyzedEntity[]) => {},

  updateAnalyzedEntity: (newAnalyzedEntity: AnalyzedEntity) => {},

  selectedInterpolation: undefined,
  setSelectedInterpolation: (analyzedEntities: InterpolationType | undefined) => {},
  interpolationsList: [],
  setInterpolationsList: (analyzedEntities: InterpolationType[]) => {},

  selectedCrossing: undefined,
  setSelectedCrossing: (crossing: CrossingType | undefined) => {},
  crossingsList: [],
  setCrossingsList: (crossings: CrossingType[]) => {},

  selectedDepth: undefined,
  setSelectedDepth: (depth: DepthSelectedItemInterface | undefined) => {},
  depthList: {},
  setDepthList: (depthList: DepthListType) => {},
  statuses: {
    approved: 0,
    rejected: 0,
    pending: 0
  },
  setStatuses: (statuses: StatusesInterface) => {},
  flagged: 0,
  setFlagged: (flagged: number) => {},
  haveComments: 0,
  setHaveComments: (haveComments: number) => {}
});

interface Props {
  children: React.ReactNode;
}
interface StatusesInterface {
  approved: number;
  rejected: number;
  pending: number;
}

export const AnalyzedEntityProvider: React.FC<Props> = (props) => {
  const { children } = props;
  const [analyzedEntity, setAnalyzedEntity] = useState<AnalyzedEntity>();
  const [analyzedEntityList, setAnalyzedEntityList] = useState<AnalyzedEntity[]>([]);
  const [selectedInterpolation, setSelectedInterpolation] = useState<InterpolationType>();
  const [interpolationsList, setInterpolationsList] = useState<InterpolationType[]>([]);
  const [selectedCrossing, setSelectedCrossing] = useState<CrossingType>();
  const [crossingsList, setCrossingsList] = useState<CrossingType[]>([]);
  const [selectedDepth, setSelectedDepth] = useState<DepthSelectedItemInterface>();
  const [depthList, setDepthList] = useState<DepthListType>({});
  const [statuses, setStatuses] = useState<StatusesInterface>({
    approved: analyzedEntityList.filter((item) => item.status === Status.Approved).length,
    rejected: analyzedEntityList.filter((item) => item.status === Status.Rejected).length,
    pending: analyzedEntityList.filter((item) => item.status === Status.Pending).length
  });
  const [flagged, setFlagged] = useState<number>(0);
  const [haveComments, setHaveComments] = useState<number>(0);

  const updateAnalyzedEntity = (newAnalyzedEntity: AnalyzedEntity): void => {
    const newId = newAnalyzedEntity?.id;
    if (!newId) {
      return;
    }

    if (newId === analyzedEntity?.id) {
      setAnalyzedEntity(newAnalyzedEntity);
    }

    const prefetched = analyzedEntityList.find((item) => item?.id === newId);

    if (prefetched) {
      const index = analyzedEntityList.indexOf(prefetched);
      const updatedList = [...analyzedEntityList];
      updatedList[index] = newAnalyzedEntity;
      setAnalyzedEntityList(updatedList);
    }
  };
  useEffect(() => {
    setStatuses({
      approved: analyzedEntityList.filter((item) => item.status === Status.Approved).length,
      rejected: analyzedEntityList.filter((item) => item.status === Status.Rejected).length,
      pending: analyzedEntityList.filter((item) => item.status === Status.Pending).length
    });
    setFlagged(analyzedEntityList.filter((item) => item.flagged).length);
    setHaveComments(analyzedEntityList.filter((item) => item.comments?.items.length).length);
  }, [analyzedEntityList]);
  return (
    <AnalyzedEntityContext.Provider
      value={{
        analyzedEntity,
        setAnalyzedEntity,
        analyzedEntityList,
        setAnalyzedEntityList,
        updateAnalyzedEntity,
        selectedInterpolation,
        setSelectedInterpolation,
        interpolationsList,
        setInterpolationsList,
        selectedCrossing,
        setSelectedCrossing,
        crossingsList,
        setCrossingsList,
        selectedDepth,
        setSelectedDepth,
        depthList,
        setDepthList,
        statuses,
        setStatuses,
        flagged,
        setFlagged,
        haveComments,
        setHaveComments
      }}>
      {children}
    </AnalyzedEntityContext.Provider>
  );
};

export default AnalyzedEntityContext;
