import { Site, useSearchAllSitesInfoLazyQuery } from '@graphql/generated';
import { useState } from 'react';
import { reportAllPagesResults } from '@helpers/reportAllPagesResults';

interface Result {
  sites: Site[];
  getSites: () => Promise<void>;
  loading: boolean;
}

export const useAllSitesQuery = (): Result => {
  const [sites, setSSites] = useState<Site[]>([]);
  const [getData, data] = useSearchAllSitesInfoLazyQuery();
  const [loading, setLoading] = useState(false);

  const getDataByFilters = async (): Promise<void> => {
    setSSites([]);
    setLoading(true);
    await reportAllPagesResults(
      getData,
      (res) => {
        const filteredItems = res?.data?.searchSites?.items?.filter((item) => item?.id) || [];
        setSSites((sites) => [...sites, ...((filteredItems ?? []) as Site[])]);
      },
      (res) => res.data?.searchSites?.nextToken
    ).finally(() => {
      setLoading(false);
    });
  };

  return {
    sites,
    getSites: getDataByFilters,
    loading
  };
};
