import styled from 'styled-components';

export const Container = styled.div`
  min-height: 50px;
  padding: 10px 20px 20px 20px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  margin: 8px;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
`;

export const Email = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
`;

export const DateContainer = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
