import styled from 'styled-components';

export const Container = styled.div`
  border-top: 2px solid rgba(0, 0, 0, 0.12);
  padding: 8px 12px;
  gap: 6px;
  align-items: center;
  display: flex;
  height: auto;
  max-height: 250px;
`;

export const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
`;

export const TextArea = styled.textarea`
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  resize: none;
  font-family: 'Montserrat';
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  height: auto;
  overflow: hidden;
  min-height: calc(1.5em + 20px);
  line-height: 1.5;
  max-height: 250px;

  &:focus {
    outline: none;
    border-color: #999;
  }
`;
