import React from 'react';
import L, { LatLngExpression } from 'leaflet';

import { Marker } from 'react-leaflet';

type PipeCrossingsProps = {
  data: Array<{
    lon: number;
    lat: number;
    crossing_index: number;
  }>;
};

export const PipeCrossings = ({ data }: PipeCrossingsProps) => {
  return (
    <>
      {data.map(({ lat, lon, crossing_index }) => {
        const position: LatLngExpression = [lat, lon];
        const size = 20;

        const icon = L.divIcon({
          className: '',
          html: `<div style="width: ${size}px; height: ${size}px; background-color: red; border-radius: 50%; display: flex; align-items: center; justify-content: center; color: white; font-size: ${12}px;">${crossing_index}</div>`,
          iconSize: [size, size]
        });

        return (
          <React.Fragment key={crossing_index}>
            <Marker position={position} icon={icon} />
          </React.Fragment>
        );
      })}
    </>
  );
};
