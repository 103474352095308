import React from 'react';
import {
  ButtonsContainer,
  Container,
  ControlsContainer,
  Divider,
  Hr,
  Slider,
  SlidersContainer,
  SliderWrapper
} from './styles';
import MapLayerToggles from './MapLayerToggles';
import StatusButtons from './StatusButtons';
import { Option } from '@components/SelectInput';
import Feedback from '../DetailsView/Feedback';
import { useMapControlsStore } from '../store';
import { SCROLL_TICK } from '@constants/interpolation';

interface Props {
  isOrthophotoLayerOn: boolean;
  onChangeOrthophotoLayerOn: (value: boolean) => void;
  isScanPathLayerOn: boolean;
  onChangeScanPathLayerOn: (value: boolean) => void;
  isUtilitiesLayerOn: boolean;
  onChangeUtilitiesLayerOn: (value: boolean) => void;
  isCrossingIndex: boolean;
  onChangeCrossingIndex: (value: boolean) => void;
  isYPeaks: boolean;
  onChangeYPeaks: (value: boolean) => void;
  isFixedScanPath: boolean;
  onChangeFixedScanPath: (value: boolean) => void;
  interpolationOptions: Option[];
  selectedInterpolationId: Option['key'];
  setSelectedInterpolationId: (key: Option['key']) => void;

  onApprove: () => void;
  onFlag: () => void;
  onReject: () => void;
}

const ControlButtons: React.FC<Props> = (props) => {
  const {
    isOrthophotoLayerOn,
    onChangeOrthophotoLayerOn,
    isScanPathLayerOn,
    onChangeScanPathLayerOn,
    isUtilitiesLayerOn,
    onChangeUtilitiesLayerOn,
    interpolationOptions,
    selectedInterpolationId,
    setSelectedInterpolationId,
    isCrossingIndex,
    onChangeCrossingIndex,
    isYPeaks,
    onChangeYPeaks,
    isFixedScanPath,
    onChangeFixedScanPath,
    onApprove,
    onFlag,
    onReject
  } = props;

  const { setMaxZoom, setMinZoom, maxZoom, minZoom } = useMapControlsStore();

  const onWheelMin = (e: React.WheelEvent<HTMLInputElement>) => {
    let value = e.deltaY > 0 ? minZoom - SCROLL_TICK : minZoom + SCROLL_TICK;
    if (value > 100) value = 100;
    if (value < 0) value = 0;
    setMinZoom(value);
  };

  const onWheelMax = (e: React.WheelEvent<HTMLInputElement>) => {
    let value = e.deltaY > 0 ? maxZoom - SCROLL_TICK : maxZoom + SCROLL_TICK;
    if (value > 100) value = 100;
    if (value < 0) value = 0;
    setMaxZoom(value);
  };

  return (
    <Container>
      <SlidersContainer>
        <SliderWrapper>
          <Slider
            type="range"
            onWheel={onWheelMin}
            onChange={(e) => setMinZoom(e.target.valueAsNumber)}
            value={minZoom}
            min={0}
            max={100}
          />
          <span
            style={{
              fontSize: 11
            }}>
            Min
          </span>
        </SliderWrapper>
        <SliderWrapper>
          <Slider
            type="range"
            onWheel={onWheelMax}
            onChange={(e) => setMaxZoom(e.target.valueAsNumber)}
            value={maxZoom}
            min={0}
            max={100}
          />
          <span
            style={{
              fontSize: 11
            }}>
            Max
          </span>
        </SliderWrapper>
      </SlidersContainer>

      <ButtonsContainer>
        <MapLayerToggles
          isYPeaks={isYPeaks}
          onChangeYPeaks={onChangeYPeaks}
          isCrossingIndex={isCrossingIndex}
          onChangeCrossingIndex={onChangeCrossingIndex}
          isOrthophotoLayerOn={isOrthophotoLayerOn}
          onChangeOrthophotoLayerOn={onChangeOrthophotoLayerOn}
          isScanPathLayerOn={isScanPathLayerOn}
          onChangeScanPathLayerOn={onChangeScanPathLayerOn}
          isUtilitiesLayerOn={isUtilitiesLayerOn}
          onChangeUtilitiesLayerOn={onChangeUtilitiesLayerOn}
          interpolationOptions={interpolationOptions}
          isFixedScanPath={isFixedScanPath}
          onChangeFixedScanPath={onChangeFixedScanPath}
          selectedInterpolationId={selectedInterpolationId}
          setSelectedInterpolationId={setSelectedInterpolationId}
        />
        <Hr />
        <ControlsContainer>
          <Feedback />
          <Divider />
          <StatusButtons onApprove={onApprove} onFlag={onFlag} onReject={onReject} />
        </ControlsContainer>
      </ButtonsContainer>
    </Container>
  );
};

export default ControlButtons;
