import React, { lazy, Ref, RefObject, useEffect, useRef, useState } from 'react';
import L, { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import { InterpolationZ } from './Interpolation';
import ScanPath from './ScanPath';
import Utility from './Utility';
import { GeoJsonObject, FeatureCollection } from 'geojson';
import ZoomHandler from './ZoomHandler';
import Orthophoto from './Orthophoto';
import { mapConfig } from '@constants/mapConfigs';
import OtherUtilities, { OtherUtilityData } from '@components/Map/OtherUtilities';
import DepthList from '@components/Map/DepthList';
import TransmitterList from '@components/Map/TransmitterList';
import { PipeCrossings } from './PipeCrossings';
import { useZoomLevel } from '@hooks/useZoomLevel';
import { YPeaks } from './YPeaks';
import { CrossingPipe } from '@hooks/prefetch/usePipesCrossings';

const Interpolation = lazy(async () => import('./Interpolation'));

export interface InterpolationData {
  bbox_transformed: [[number, number], [number, number]];
  bbox_original: [[number, number], [number, number]];
  interpolation: InterpolationZ;
}

interface Props {
  interpolationData?: InterpolationData;
  scanPathData?: FeatureCollection;
  yPeaks: LatLngExpression[];
  utilityData?: GeoJsonObject;
  otherUtilitiesData?: OtherUtilityData[];
  center?: LatLngExpression;
  isOrthophotoLayerOn: boolean;
  isInterpolationLayerOn: boolean;
  isScanPathLayerOn: boolean;
  isUtilitiesLayerOn: boolean;
  isCrossingIndex: boolean;
  isYPeaks: boolean;
  minInterpolation: number;
  maxInterpolation: number;
  mapRef?: Ref<L.Map>;
  pipesCrossing: CrossingPipe[];
}

const Map: React.FC<Props> = (props) => {
  const {
    interpolationData,
    scanPathData,
    utilityData,
    otherUtilitiesData,
    center,
    isScanPathLayerOn,
    isOrthophotoLayerOn,
    isUtilitiesLayerOn,
    isInterpolationLayerOn,
    maxInterpolation,
    minInterpolation,
    mapRef,
    isCrossingIndex,
    pipesCrossing,
    isYPeaks,
    yPeaks
  } = props;

  const zoom = useZoomLevel();
  const showLayer = zoom.zoomLevel >= 22;

  return (
    <MapContainer
      key="map"
      id="map"
      ref={mapRef}
      center={center}
      zoom={mapConfig.zoom}
      style={{
        height: '100%',
        width: '100%'
      }}
      maxZoom={mapConfig.maxZoom}>
      <TileLayer
        maxZoom={mapConfig.maxZoom}
        maxNativeZoom={mapConfig.maxNativeZoom}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomHandler />
      {isInterpolationLayerOn && interpolationData && (
        <Interpolation
          transformedBox={interpolationData.bbox_transformed}
          interpolation={interpolationData.interpolation}
          opacity={0.8}
          maxInterpolation={maxInterpolation}
          minInterpolation={minInterpolation}
        />
      )}
      {isYPeaks && showLayer && <YPeaks data={yPeaks} />}
      {isCrossingIndex && showLayer && <PipeCrossings data={pipesCrossing} />}
      {showLayer && <DepthList />}
      {isScanPathLayerOn && <ScanPath scanPathData={scanPathData} />}
      {isOrthophotoLayerOn && <Orthophoto />}
      {isUtilitiesLayerOn && <OtherUtilities otherUtilitiesData={otherUtilitiesData} />}
      <Utility utilityData={utilityData} />
      <TransmitterList />
    </MapContainer>
  );
};

export default Map;
