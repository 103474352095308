import React, { ChangeEvent, useEffect, useRef } from 'react';
import { ButtonWrapper, Container, TextArea } from './styles';
import Button from '@components/Button';

interface Props {
  onSend?: () => void;
  text: string;
  setText: (val: string) => void;
  sendDisabled?: boolean;
}

const Input: React.FC<Props> = (props) => {
  const { onSend, text, setText, sendDisabled } = props;
  const onChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setText(e.target.value);
  };
  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey && !sendDisabled) {
      e.preventDefault();
      onSend?.();
    }
  };

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = 'auto';
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    };

    const textAreaElement = textAreaRef.current;
    if (textAreaElement) {
      textAreaElement.addEventListener('input', handleResize);
    }

    return () => {
      if (textAreaElement) {
        textAreaElement.removeEventListener('input', handleResize);
      }
    };
  }, []);

  return (
    <Container>
      <TextArea
        rows={1}
        value={text}
        ref={textAreaRef}
        onChange={onChange}
        onKeyDown={onEnterPress}></TextArea>
      <ButtonWrapper>
        <Button onClick={onSend} disabled={sendDisabled}>
          Send
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default Input;
