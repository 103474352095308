import { useEffect, useState } from 'react';
import Select, { Props, MultiValue } from 'react-select';

export type Option = {
  value: string;
  label: string;
};

export const SELECT_ALL = 'Select All';

export const MultiSelect = ({
  onChange,
  options,
  defaultValue,
  ...rest
}: Omit<Props<Option>, 'onChange' | 'options'> & {
  onChange: (newVal: string[]) => void;
  options: Option[];
}) => {
  const [value, setValue] = useState<MultiValue<Option>>(
    defaultValue ? ([defaultValue] as MultiValue<Option>) : []
  );

  useEffect(() => {
    if (value.find((s) => s.value === SELECT_ALL)) {
      onChange(options.map((s) => s.value).filter((s) => s !== SELECT_ALL));
    } else {
      onChange(value.map((s) => s.value).filter((s) => s !== SELECT_ALL));
    }
  }, [value, options]);

  const handleChange = (newValue: MultiValue<Option>) => {
    if (!newValue) return;
    if (newValue.at(-1)?.value === SELECT_ALL) {
      setValue([{ label: SELECT_ALL, value: SELECT_ALL }]);
    } else {
      setValue(newValue.filter((s) => s.value !== SELECT_ALL));
    }
  };

  return (
    <Select
      {...rest}
      defaultValue={defaultValue}
      value={value}
      options={options}
      getOptionLabel={(p: Option) => p.label}
      getOptionValue={(p: Option) => p.value}
      onChange={(newVal) => handleChange(newVal as MultiValue<Option>)}
      isMulti
    />
  );
};
